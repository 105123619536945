.t {
    top: -5px;
}


.lb{
    position: absolute;
    top: 0;
    transform: translateY(-55%);
    z-index: 2;
    background-color: white;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  
  }